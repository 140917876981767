import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { delay, map, Observable, of } from 'rxjs';
import { MockApiService } from '@core/services/mock-api.service';
import { RouteParam } from '@shared/enums/route-segment.enum';

@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
  private readonly RESPONSE_DELAY_MS = 0;

  constructor(private readonly mockApiService: MockApiService) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const backendRootUrl = 'mockbackend';

    const method = request.method;
    const path = request.url.slice(backendRootUrl.length);
    const language = request.params.get(RouteParam.Language) || 'en';

    if (method === 'GET' && path.startsWith('/testkit-manual')) {
      return this.getResponse$(this.mockApiService.getTestKitManual(language));
    }
    if (method === 'GET' && path.includes('/results/customer-products')) {
      return this.getResponse$(this.mockApiService.getCustomerProducts());
    }
    if (method === 'GET' && path.includes('/results/surveys/')) {
      const purchaseId = path.split('/').pop();
      return this.getResponse$(this.mockApiService.getSurveyResults(purchaseId!));
    }
    return next.handle(request);
  }

  private getResponse$(response: HttpResponse<unknown>): Observable<HttpEvent<unknown>> {
    return of(response).pipe(
      delay(this.RESPONSE_DELAY_MS),
      map((httpResponse) => {
        if (httpResponse.ok) {
          return httpResponse;
        }
        throw new HttpErrorResponse({ status: httpResponse.status, error: httpResponse.body });
      })
    );
  }
}
