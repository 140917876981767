/* eslint-disable unicorn/no-zero-fractions */
import { http, HttpResponse } from 'msw';
import { BiomarkerResult, CustomerProductResultBatch, ProductType, SurveyResult, SurveyStatus } from '@dd/results-client-sdk';

export const mockResponse = {
  entries: [
    {
      productSku: 'diabetes',
      biomarkers: [
        {
          biomarkerId: '039cd836-0a99-4122-9338-f4c7777390ea',
          latestValueRange: 'HIGH_RISK',
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 2.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 3,
              lowerBoundIsInclusive: true,
              upperBound: 6,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'LDL',
          unit: 'mmol/l'
        },
        {
          biomarkerId: '2f38f812-a66c-4ec4-bcf7-7e95ceb3b4c5',
          latestValueRange: 'HEALTHY',
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 24.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 25,
              lowerBoundIsInclusive: true,
              upperBound: 74.99,
              upperBoundIsInclusive: false,
              valueLevel: 'LOWERED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 75,
              lowerBoundIsInclusive: true,
              upperBound: 150,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 150.01,
              lowerBoundIsInclusive: false,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 250.01,
              lowerBoundIsInclusive: true,
              upperBound: 500,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'D',
          unit: 'nmol/l'
        },
        {
          biomarkerId: 'fd13e0e0-d5b2-4814-9a00-53cf2c781a32',
          latestValueRange: 'HEALTHY',
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 24.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 25,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 250.01,
              lowerBoundIsInclusive: true,
              upperBound: 500,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Ferritine',
          unit: 'ug/l'
        },
        {
          biomarkerId: '1c8b553e-e986-4811-a9d4-84ca5fb5e878',
          latestValueRange: 'HEALTHY',
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 0.91,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 0.92,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 2,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            }
          ],
          name: 'HDL',
          unit: 'mmol/l'
        },
        {
          biomarkerId: '8313b9e9-1489-49d7-b530-81beb069332e',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 49.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 50,
              lowerBoundIsInclusive: true,
              upperBound: 100,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'ALAT',
          unit: 'U/l'
        },
        {
          biomarkerId: '03a6bda9-8b50-46fc-9378-ade02032d17b',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 39.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 40,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 130,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 130.01,
              lowerBoundIsInclusive: true,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Alkalische fosfatase',
          unit: 'U/l'
        },
        {
          biomarkerId: '32c68851-2edc-4f6d-a57c-6741f6e96220',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 9.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 10,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 49,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 49.01,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 100,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            }
          ],
          name: 'B11',
          unit: 'nmol/l'
        },
        {
          biomarkerId: '46a4f93b-326a-48a8-8a56-8f4fbaa8b7f9',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 144.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 145,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 569,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 569.01,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 1000,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            }
          ],
          name: 'B12',
          unit: 'pmol/l'
        },
        {
          biomarkerId: '7a569f9c-2edb-49e6-8423-63c2c6af21be',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 19.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 20,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 250.01,
              lowerBoundIsInclusive: true,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'B6',
          unit: 'nmol/l'
        },
        {
          biomarkerId: '4633e753-607a-428f-b3ad-68d3795ada40',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 20.49,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 20.5,
              lowerBoundIsInclusive: true,
              upperBound: 40,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Bilirubine',
          unit: 'µmol/l'
        },
        {
          biomarkerId: 'ba4dd3c6-9a11-4948-a313-8f7cccea3ed7',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 2.14,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 2.15,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 2.55,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 2.56,
              lowerBoundIsInclusive: true,
              upperBound: 5,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Calcium, totaal',
          unit: 'mmol/l'
        },
        {
          biomarkerId: '514f0797-f3da-4ba3-aae5-abec8b73f4f8',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 96.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 97,
              lowerBoundIsInclusive: true,
              upperBound: 107,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 107.01,
              lowerBoundIsInclusive: true,
              upperBound: 0,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Chloride',
          unit: 'mmol/l'
        },
        {
          biomarkerId: '264396c6-3eac-4b2b-ac2e-31108710a369',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 60.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 61,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 113,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 113.01,
              lowerBoundIsInclusive: true,
              upperBound: 230,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Creatinine',
          unit: 'mycro-mol/l'
        },
        {
          biomarkerId: '63ce922e-5622-452a-8ad3-273acaa410cf',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 59.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 60,
              lowerBoundIsInclusive: true,
              upperBound: 89.99,
              upperBoundIsInclusive: false,
              valueLevel: 'LOWERED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 90,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 200,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            }
          ],
          name: 'eGFR',
          unit: 'ml/min/1.73m²'
        },
        {
          biomarkerId: '707227f3-1e83-4699-8fd9-4fc94b5ba15f',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 8.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 9,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 24,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 24.01,
              lowerBoundIsInclusive: true,
              upperBound: 50,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'fT4',
          unit: 'pmol/l'
        },
        {
          biomarkerId: '73bafbb1-1328-4b02-865d-c18e80edef65',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 59.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 60,
              lowerBoundIsInclusive: true,
              upperBound: 120,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'GGT',
          unit: 'U/l'
        },
        {
          biomarkerId: '94859551-5e67-4a17-8e4f-847f3ba428fd',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 20,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 41.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 42,
              lowerBoundIsInclusive: true,
              upperBound: 52.99,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 53,
              lowerBoundIsInclusive: true,
              upperBound: 100,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'HbA1c',
          unit: 'mmol/mol'
        },
        {
          biomarkerId: 'f1880304-72cd-4842-a64d-588abe8faf8f',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 34.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 35,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 79,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            }
          ],
          name: 'Holotranscobalamine',
          unit: 'pmol/l'
        },
        {
          biomarkerId: '1e519954-627a-4ddb-9f9e-e2e7434f6292',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 13.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 14,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 35,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 35.01,
              lowerBoundIsInclusive: true,
              upperBound: 70,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'IJzer',
          unit: 'µmol/l'
        },
        {
          biomarkerId: '76e6efe4-c5e9-49d6-8fbe-9493c45a2bdb',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 0.65,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 0.66,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 1,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 1.01,
              lowerBoundIsInclusive: true,
              upperBound: 2,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Magnesium',
          unit: 'mmol/l'
        },
        {
          biomarkerId: '58608d69-5c6e-4555-a91d-febab0a89970',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 134.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 135,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 145,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 145.01,
              lowerBoundIsInclusive: true,
              upperBound: 250,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Natrium',
          unit: 'mmol/l'
        },
        {
          biomarkerId: 'fa1a11df-de51-46fa-85b1-d0525b1a794b',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 2.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 3,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: true,
              upperBound: 6,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            }
          ],
          name: 'PSA',
          unit: 'µg/l'
        },
        {
          biomarkerId: '554900d9-522e-4382-aa77-8a93fb8c6b8d',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 4.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 5,
              lowerBoundIsInclusive: true,
              upperBound: 8,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 8.01,
              lowerBoundIsInclusive: true,
              upperBound: 16,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Totaal cholesterol',
          unit: 'mmol/l'
        },
        {
          biomarkerId: 'dc2a9ba3-ea7a-46ee-a19d-a5334139fff4',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 1.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 2,
              lowerBoundIsInclusive: true,
              upperBound: 5,
              upperBoundIsInclusive: true,
              valueLevel: 'ELEVATED',
              valueRange: 'INCREASED_RISK'
            },
            {
              lowerBound: 5.01,
              lowerBoundIsInclusive: true,
              upperBound: 10,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Totaal cholesterol/HDL',
          unit: null
        },
        {
          biomarkerId: '22dacbcb-1b13-42cf-931b-d0888c22aadf',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 199.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 200,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 410,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 410.01,
              lowerBoundIsInclusive: true,
              upperBound: 800,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Transferrin',
          unit: 'mg/dl'
        },
        {
          biomarkerId: '75b30b19-a5ea-4e3b-87da-49ed23aece7d',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 1.99,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 2,
              lowerBoundIsInclusive: true,
              upperBound: 4,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Triglycerides',
          unit: null
        },
        {
          biomarkerId: '0ae3bb38-25a3-419b-9a1a-b8ab83156a54',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 0.39,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 0.4,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 4,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 4.01,
              lowerBoundIsInclusive: true,
              upperBound: 8,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'TSH',
          unit: 'mU/l'
        },
        {
          biomarkerId: 'e540f9dd-c711-4063-83c9-887fd233a6ac',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 2.76,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 2.77,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 8.1,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 8.11,
              lowerBoundIsInclusive: true,
              upperBound: 16,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Ureum',
          unit: 'mmol/l'
        },
        {
          biomarkerId: 'bec8f8e9-6e7b-4746-8394-f94cbe2f9c68',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 1.04,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 1.05,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 2.8,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 2.81,
              lowerBoundIsInclusive: true,
              upperBound: 6,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Vit A',
          unit: 'µmol/l'
        },
        {
          biomarkerId: '990f2869-e5b4-4c57-b6d0-305edf1e298c',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 27.99,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 28,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 85,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 85.01,
              lowerBoundIsInclusive: true,
              upperBound: 180,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Vit B1',
          unit: 'nmol/l'
        },
        {
          biomarkerId: 'a77cb2a5-5c91-4f2d-b460-9d47dc2a0bf3',
          latestValueRange: null,
          levelReferences: [
            {
              lowerBound: 0,
              lowerBoundIsInclusive: true,
              upperBound: 12.79,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_LOW',
              valueRange: 'HIGH_RISK'
            },
            {
              lowerBound: 12.8,
              lowerBoundIsInclusive: true,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: null,
              upperBoundIsInclusive: false,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: null,
              lowerBoundIsInclusive: false,
              upperBound: 41.8,
              upperBoundIsInclusive: true,
              valueLevel: 'NORMAL',
              valueRange: 'HEALTHY'
            },
            {
              lowerBound: 41.81,
              lowerBoundIsInclusive: true,
              upperBound: 80,
              upperBoundIsInclusive: true,
              valueLevel: 'TOO_HIGH',
              valueRange: 'HIGH_RISK'
            }
          ],
          name: 'Vit E',
          unit: 'µmol/l'
        }
      ],
      isNew: true,
      productHandle: 'diabetes',
      id: 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe',
      status: SurveyStatus.Finished,
      receivedAt: Date.now().toString(),
      productType: ProductType.Test
    },
    {
      id: 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe',
      status: SurveyStatus.Finished,
      productType: ProductType.Survey,
      isNew: true,
      productSku: 'mto-survey',
      productHandle: 'mto-survey',
      receivedAt: Date.now().toString()
    },
    {
      id: 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe-fail',
      status: SurveyStatus.Todo,
      productType: ProductType.Survey,
      isNew: true,
      productSku: 'mto-survey',
      productHandle: 'mto-survey',
      receivedAt: Date.now().toString()
    },
    {
      id: 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe-fail',
      status: SurveyStatus.Todo,
      productType: ProductType.Survey,
      isNew: true,
      productSku: 'survey_rand36',
      productHandle: 'survey_rand36',
      receivedAt: Date.now().toString()
    },
    {
      id: 'rand-36-finished',
      status: SurveyStatus.Finished,
      productType: ProductType.Survey,
      isNew: true,
      productSku: 'survey_rand36',
      productHandle: 'survey_rand36',
      receivedAt: Date.now().toString()
    }
  ],
  totalCount: 2
} as unknown as CustomerProductResultBatch;

export const ResultsHandler = [
  http.get<never, never, CustomerProductResultBatch>('/results/customer-products', () => {
    return HttpResponse.json(mockResponse as unknown as CustomerProductResultBatch);
  }),
  http.get<never, never, BiomarkerResult>('/results/biomarkers/:id', () => {
    return HttpResponse.json({
      testsResults: [
        {
          productSku: 'cholesterol',
          isNew: false,
          resultValue: 74.9,
          valueRange: 'HEALTHY',
          valueLevel: 'NORMAL',
          productHandle: 'cholesterol',
          receivedAt: '2022-12-16T13:30:00Z'
        },
        {
          productSku: 'cholesterol',
          isNew: false,
          resultValue: 0.91,
          valueRange: 'HIGH_RISK',
          valueLevel: 'TOO_LOW',
          productHandle: 'cholesterol',
          receivedAt: '2022-12-13T14:40:00Z'
        },
        {
          productSku: 'cholesterol',
          isNew: false,
          resultValue: 0.91,
          valueRange: 'HIGH_RISK',
          valueLevel: 'TOO_LOW',
          productHandle: 'cholesterol',
          receivedAt: '2022-12-13T13:30:00Z'
        },
        {
          productSku: 'cholesterol',
          isNew: false,
          resultValue: 5,
          valueRange: 'HEALTHY',
          valueLevel: 'NORMAL',
          productHandle: 'cholesterol',
          receivedAt: '2022-12-09T14:35:00Z'
        }
      ]
    } as BiomarkerResult);
  }),
  http.get<{ id: string }, never, SurveyResult>('/results/surveys/:id', ({ params }) => {
    if (params.id === 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe-fail') {
      return HttpResponse.json(null, {
        status: 404,
        statusText: 'Not Found'
      });
    }
    const mtoSurveyResult: SurveyResult = {
      formId: '1',
      productHandle: 'mto-survey',
      results: [
        {
          value: 54.1,
          variableName: 'physical_health'
        },
        {
          value: 60.0,
          variableName: 'mental_health'
        }
      ]
    };

    const rand36SurveyResult: SurveyResult = {
      formId: '1',
      productHandle: 'survey_rand36',
      results: [
        {
          variableName: 'physical_function',
          value: 75.5
        },
        {
          variableName: 'social_function',
          value: 85.5
        },
        {
          variableName: 'role_limitation_physical_function',
          value: 65.2
        },
        {
          variableName: 'role_limitation_emotional_function',
          value: 70.0
        },
        {
          variableName: 'mental_function',
          value: 90.1
        },
        {
          variableName: 'vitality',
          value: 80.2
        },
        {
          variableName: 'pain',
          value: 60.6
        },
        {
          variableName: 'general_health',
          value: 95.0
        },
        {
          variableName: 'health_change',
          value: 50.7
        }
      ]
    };
    return HttpResponse.json(params.id === 'rand-36-finished' ? rand36SurveyResult : mtoSurveyResult);
  })
];
