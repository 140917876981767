import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { appPlaceholderIcon } from '@icons/placeholder';
import { TextFieldModule } from '@angular/cdk/text-field';
import { appArrowDownIcon } from '@icons/arrow-down';
import { appPlusIcon } from '@icons/plus';
import { appMinusIcon } from '@icons/minus';
import { ReplaceValuePipe } from '@shared/pipes/replace-value/replace-value.pipe';
import { InterpolatePipe } from '@shared/pipes/interpolate/interpolate.pipe';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { appDoubleArrowLeftIcon } from '@icons/double-arrow-left';
import { appArrowLeftIcon } from '@icons/arrow-left';
import { appArrowRightIcon } from '@icons/arrow-right';
import { appSurveyIcon } from '@icons/results/survey';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InputComponent } from './components/input/input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ComplexTranslationModule } from './components/complex-translation/complex-translation.module';
import { ScrollerComponent } from './components/scroller/scroller.component';
import { RadioComponent } from './components/radio/radio.component';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SelectComponent } from './components/select/select.component';
import { PluralTranslatePipe } from './pipes/plural-translate/plural-translate.pipe';
import { PaymentIconsComponent } from './components/payment-icons/payment-icons.component';
import { EmptyIllustrationComponent } from './components/empty-illustration/empty-illustration.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LocalizeUrlPipe } from './pipes/localize-url/localize-url.pipe';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { ScrollManagerModule } from './directives/scroll-manager/scroll-manager.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { IncludesPipe } from './pipes/includes/includes.pipe';
import { DisabledControlDirective } from './directives/disabled-control/disabled-control.directive';
import { PhoneObfuscatePipe } from './pipes/phone-obfuscate/phone-obfuscate.pipe';
import { PushTagOnClickDirective } from './directives/push-tag-on-click/push-tag-on-click.directive';
import { HappyIllustrationComponent } from './components/happy-illustration/happy-illustration.component';
import { BarcodeActivationSuccessComponent } from './components/barcode-activation-success/barcode-activation-success.component';
import { DynamicMenuItemComponent } from './components/dynamic-menu-item/dynamic-menu-item.component';
import { ButtonComponent } from './components/button/button.component';

const sharedComponents = [
  NavigationComponent,
  QuantityInputComponent,
  TextareaComponent,
  CheckboxComponent,
  RadioComponent,
  SelectComponent,
  PaymentIconsComponent,
  EmptyIllustrationComponent,
  ProfilePictureComponent,
  PageNotFoundComponent,
  HappyIllustrationComponent,
  BarcodeActivationSuccessComponent
];

const sharedPipes = [PluralTranslatePipe, IncludesPipe, PhoneObfuscatePipe, ReplaceValuePipe, InterpolatePipe];

const sharedDirectives = [DisabledControlDirective, PushTagOnClickDirective];

@NgModule({
  declarations: [sharedComponents, sharedPipes, sharedDirectives],
  imports: [
    CommonModule,
    NgxJsonLdModule,
    TranslateModule,
    ScrollManagerModule,
    FormsModule,
    ReactiveFormsModule,
    ComplexTranslationModule,
    TextFieldModule,
    RouterModule,
    LetDirective,
    SvgIconComponent,
    DynamicMenuItemComponent,
    ButtonComponent,
    SvgIconComponent,
    InputComponent,
    ButtonComponent,
    SafeHtmlPipe,
    ScrollerComponent,
    LocalizeUrlPipe
  ],
  providers: [
    provideSvgIcons([
      appPlaceholderIcon,
      appArrowDownIcon,
      appArrowLeftIcon,
      appArrowRightIcon,
      appPlusIcon,
      appMinusIcon,
      appDoubleArrowLeftIcon,
      appSurveyIcon
    ])
  ],
  exports: [
    CommonModule,
    LetDirective,
    TranslateModule,
    NgxJsonLdModule,
    ScrollManagerModule,
    SvgIconComponent,
    ButtonComponent,
    SafeHtmlPipe,
    ScrollerComponent,
    DynamicMenuItemComponent,
    InputComponent,
    LocalizeUrlPipe,
    sharedComponents,
    sharedPipes,
    sharedDirectives
  ]
})
export class SharedModule {}
