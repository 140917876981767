/* eslint-disable unicorn/no-zero-fractions */

import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { mockResponse } from '@shared/msw/lib/handlers/results/results-handler';
import { SurveyResult } from '@dd/results-client-sdk';
import testkitManualDataEN from './mock-data/testkit-manual-en.json';
import testkitManualDataNL from './mock-data/testkit-manual-nl.json';

@Injectable({
  providedIn: 'root'
})
export class MockApiService {
  public getTestKitManual(language: string): HttpResponse<unknown> {
    const testkitManualData = language === 'nl' ? testkitManualDataNL : testkitManualDataEN;

    return new HttpResponse({
      status: HttpStatusCode.Ok,
      body: testkitManualData
    });
  }

  public getCustomerProducts(): HttpResponse<unknown> {
    return new HttpResponse({
      status: HttpStatusCode.Ok,
      body: mockResponse
    });
  }

  public getSurveyResults(id: string): HttpResponse<unknown> {
    if (id === 'fcf9397a-b204-41d5-9cf9-612bbe9aadbe-fail') {
      return new HttpResponse({
        status: HttpStatusCode.NotFound,
        statusText: 'Not Found'
      });
    }
    const mtoSurveyResult: SurveyResult = {
      formId: '1',
      productHandle: 'mto-survey',
      results: [
        {
          value: 54.1,
          variableName: 'physical_health'
        },
        {
          value: 60,
          variableName: 'mental_health'
        }
      ]
    };

    const rand36SurveyResult: SurveyResult = {
      formId: '1',
      productHandle: 'survey_rand36',
      results: [
        {
          variableName: 'physical_function',
          value: 75.5
        },
        {
          variableName: 'social_function',
          value: 85.5
        },
        {
          variableName: 'role_limitation_physical_function',
          value: 65.2
        },
        {
          variableName: 'role_limitation_emotional_function',
          value: 70
        },
        {
          variableName: 'mental_function',
          value: 90.1
        },
        {
          variableName: 'vitality',
          value: 80.2
        },
        {
          variableName: 'pain',
          value: 60.6
        },
        {
          variableName: 'general_health',
          value: 95
        },
        {
          variableName: 'health_change',
          value: 50.7
        }
      ]
    };
    return new HttpResponse({
      status: HttpStatusCode.Ok,
      body: id === 'rand-36-finished' ? rand36SurveyResult : mtoSurveyResult
    });
  }
}
